"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddressBodyToJSON = exports.AddressBodyFromJSONTyped = exports.AddressBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function AddressBodyFromJSON(json) {
    return AddressBodyFromJSONTyped(json, false);
}
exports.AddressBodyFromJSON = AddressBodyFromJSON;
function AddressBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'line1': !(0, runtime_1.exists)(json, 'line1') ? undefined : json['line1'],
        'line2': !(0, runtime_1.exists)(json, 'line2') ? undefined : json['line2'],
        'locality': json['locality'],
        'administrativeArea': json['administrativeArea'],
        'postalCode': !(0, runtime_1.exists)(json, 'postalCode') ? undefined : json['postalCode'],
        'country': json['country'],
        'placeId': !(0, runtime_1.exists)(json, 'placeId') ? undefined : json['placeId'],
    };
}
exports.AddressBodyFromJSONTyped = AddressBodyFromJSONTyped;
function AddressBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'line1': value.line1,
        'line2': value.line2,
        'locality': value.locality,
        'administrativeArea': value.administrativeArea,
        'postalCode': value.postalCode,
        'country': value.country,
        'placeId': value.placeId,
    };
}
exports.AddressBodyToJSON = AddressBodyToJSON;
