"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateBusinessBodyBaseToJSON = exports.CreateBusinessBodyBaseFromJSONTyped = exports.CreateBusinessBodyBaseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function CreateBusinessBodyBaseFromJSON(json) {
    return CreateBusinessBodyBaseFromJSONTyped(json, false);
}
exports.CreateBusinessBodyBaseFromJSON = CreateBusinessBodyBaseFromJSON;
function CreateBusinessBodyBaseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': !(0, runtime_1.exists)(json, 'type') ? undefined : (0, _1.BusinessTypeFromJSON)(json['type']),
        'brandSize': !(0, runtime_1.exists)(json, 'brandSize') ? undefined : (0, _1.RestaurantBrandSizeFromJSON)(json['brandSize']),
        'parent': !(0, runtime_1.exists)(json, 'parent') ? undefined : json['parent'],
        'name': json['name'],
        'internalName': !(0, runtime_1.exists)(json, 'internalName') ? undefined : json['internalName'],
        'timeZone': !(0, runtime_1.exists)(json, 'timeZone') ? undefined : json['timeZone'],
        'currency': !(0, runtime_1.exists)(json, 'currency') ? undefined : json['currency'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'email': !(0, runtime_1.exists)(json, 'email') ? undefined : json['email'],
        'specialInstructions': !(0, runtime_1.exists)(json, 'specialInstructions') ? undefined : json['specialInstructions'],
        'prepTime': !(0, runtime_1.exists)(json, 'prepTime') ? undefined : json['prepTime'],
        'phoneNumber': !(0, runtime_1.exists)(json, 'phoneNumber') ? undefined : (0, _1.PhoneNumberBodyFromJSON)(json['phoneNumber']),
        'address': (0, _1.AddressFromJSON)(json['address']),
        'taxonomies': !(0, runtime_1.exists)(json, 'taxonomies') ? undefined : json['taxonomies'],
        'handoffOptions': !(0, runtime_1.exists)(json, 'handoffOptions') ? undefined : (json['handoffOptions'].map(_1.HandoffOptionsFromJSON)),
        'merchantId': !(0, runtime_1.exists)(json, 'merchantId') ? undefined : json['merchantId'],
        'website': !(0, runtime_1.exists)(json, 'website') ? undefined : json['website'],
    };
}
exports.CreateBusinessBodyBaseFromJSONTyped = CreateBusinessBodyBaseFromJSONTyped;
function CreateBusinessBodyBaseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, _1.BusinessTypeToJSON)(value.type),
        'brandSize': (0, _1.RestaurantBrandSizeToJSON)(value.brandSize),
        'parent': value.parent,
        'name': value.name,
        'internalName': value.internalName,
        'timeZone': value.timeZone,
        'currency': value.currency,
        'description': value.description,
        'email': value.email,
        'specialInstructions': value.specialInstructions,
        'prepTime': value.prepTime,
        'phoneNumber': (0, _1.PhoneNumberBodyToJSON)(value.phoneNumber),
        'address': (0, _1.AddressToJSON)(value.address),
        'taxonomies': value.taxonomies,
        'handoffOptions': value.handoffOptions === undefined ? undefined : (value.handoffOptions.map(_1.HandoffOptionsToJSON)),
        'merchantId': value.merchantId,
        'website': value.website,
    };
}
exports.CreateBusinessBodyBaseToJSON = CreateBusinessBodyBaseToJSON;
