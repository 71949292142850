"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferralLinkTextToJSON = exports.ReferralLinkTextFromJSONTyped = exports.ReferralLinkTextFromJSON = void 0;
var runtime_1 = require("../runtime");
function ReferralLinkTextFromJSON(json) {
    return ReferralLinkTextFromJSONTyped(json, false);
}
exports.ReferralLinkTextFromJSON = ReferralLinkTextFromJSON;
function ReferralLinkTextFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'header': json['header'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
    };
}
exports.ReferralLinkTextFromJSONTyped = ReferralLinkTextFromJSONTyped;
function ReferralLinkTextToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'header': value.header,
        'description': value.description,
    };
}
exports.ReferralLinkTextToJSON = ReferralLinkTextToJSON;
