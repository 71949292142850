"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestaurantResponseForMenuOrderOwnToJSON = exports.RestaurantResponseForMenuOrderOwnFromJSONTyped = exports.RestaurantResponseForMenuOrderOwnFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function RestaurantResponseForMenuOrderOwnFromJSON(json) {
    return RestaurantResponseForMenuOrderOwnFromJSONTyped(json, false);
}
exports.RestaurantResponseForMenuOrderOwnFromJSON = RestaurantResponseForMenuOrderOwnFromJSON;
function RestaurantResponseForMenuOrderOwnFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'icon': !(0, runtime_1.exists)(json, 'icon') ? undefined : (0, _1.AssetFromJSON)(json['icon']),
        'url': json['url'],
    };
}
exports.RestaurantResponseForMenuOrderOwnFromJSONTyped = RestaurantResponseForMenuOrderOwnFromJSONTyped;
function RestaurantResponseForMenuOrderOwnToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'icon': (0, _1.AssetToJSON)(value.icon),
        'url': value.url,
    };
}
exports.RestaurantResponseForMenuOrderOwnToJSON = RestaurantResponseForMenuOrderOwnToJSON;
