"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MutexLockTypeToJSON = exports.MutexLockTypeFromJSONTyped = exports.MutexLockTypeFromJSON = exports.MutexLockType = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var MutexLockType;
(function (MutexLockType) {
    MutexLockType["BRANDMAPAUTOMINT"] = "BRAND_MAP_AUTOMINT";
})(MutexLockType = exports.MutexLockType || (exports.MutexLockType = {}));
function MutexLockTypeFromJSON(json) {
    return MutexLockTypeFromJSONTyped(json, false);
}
exports.MutexLockTypeFromJSON = MutexLockTypeFromJSON;
function MutexLockTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.MutexLockTypeFromJSONTyped = MutexLockTypeFromJSONTyped;
function MutexLockTypeToJSON(value) {
    return value;
}
exports.MutexLockTypeToJSON = MutexLockTypeToJSON;
