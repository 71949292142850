"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModelApiResponseToJSON = exports.ModelApiResponseFromJSONTyped = exports.ModelApiResponseFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function ModelApiResponseFromJSON(json) {
    return ModelApiResponseFromJSONTyped(json, false);
}
exports.ModelApiResponseFromJSON = ModelApiResponseFromJSON;
function ModelApiResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'type': (0, _1.ApiResponseTypeFromJSON)(json['type']),
        'menuOrder': !(0, runtime_1.exists)(json, 'menuOrder') ? undefined : json['menuOrder'],
        'business': !(0, runtime_1.exists)(json, 'business') ? undefined : json['business'],
        'insertId': json['insertId'],
        'route': !(0, runtime_1.exists)(json, 'route') ? undefined : json['route'],
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'status': !(0, runtime_1.exists)(json, 'status') ? undefined : json['status'],
    };
}
exports.ModelApiResponseFromJSONTyped = ModelApiResponseFromJSONTyped;
function ModelApiResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'type': (0, _1.ApiResponseTypeToJSON)(value.type),
        'menuOrder': value.menuOrder,
        'business': value.business,
        'insertId': value.insertId,
        'route': value.route,
        'url': value.url,
        'status': value.status,
    };
}
exports.ModelApiResponseToJSON = ModelApiResponseToJSON;
