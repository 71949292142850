"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.BrandMapToJSON = exports.BrandMapFromJSONTyped = exports.BrandMapFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function BrandMapFromJSON(json) {
    return BrandMapFromJSONTyped(json, false);
}
exports.BrandMapFromJSON = BrandMapFromJSON;
function BrandMapFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': !(0, runtime_1.exists)(json, 'name') ? undefined : json['name'],
        'brand': (0, _1.BrandFromJSON)(json['brand']),
        'merchant': !(0, runtime_1.exists)(json, 'merchant') ? undefined : (0, _1.MerchantFromJSON)(json['merchant']),
        'slug': !(0, runtime_1.exists)(json, 'slug') ? undefined : json['slug'],
        'bannerImage': !(0, runtime_1.exists)(json, 'bannerImage') ? undefined : (0, _1.AssetFromJSON)(json['bannerImage']),
        'defaultLocation': !(0, runtime_1.exists)(json, 'defaultLocation') ? undefined : (0, _1.AddressFromJSON)(json['defaultLocation']),
        'status': (0, _1.BrandMapStatusFromJSON)(json['status']),
        'businesses': !(0, runtime_1.exists)(json, 'businesses') ? undefined : (json['businesses'].map(_1.BusinessFromJSON)),
        'mainPromo': !(0, runtime_1.exists)(json, 'mainPromo') ? undefined : (0, _1.OrderDiscountFromJSON)(json['mainPromo']),
        'promos': !(0, runtime_1.exists)(json, 'promos') ? undefined : (json['promos'].map(_1.OrderDiscountFromJSON)),
        'promoHero': !(0, runtime_1.exists)(json, 'promoHero') ? undefined : (0, _1.AssetFromJSON)(json['promoHero']),
        'promoBackground': !(0, runtime_1.exists)(json, 'promoBackground') ? undefined : (0, _1.AssetFromJSON)(json['promoBackground']),
        'promoCartHero': !(0, runtime_1.exists)(json, 'promoCartHero') ? undefined : (0, _1.AssetFromJSON)(json['promoCartHero']),
        'promoCartBackground': !(0, runtime_1.exists)(json, 'promoCartBackground') ? undefined : (0, _1.AssetFromJSON)(json['promoCartBackground']),
        'colorTheme': (0, _1.BrandMapColorThemeFromJSON)(json['colorTheme']),
        'colorBackground': !(0, runtime_1.exists)(json, 'colorBackground') ? undefined : json['colorBackground'],
        'defaultBusiness': !(0, runtime_1.exists)(json, 'defaultBusiness') ? undefined : json['defaultBusiness'],
        'promoTitle': !(0, runtime_1.exists)(json, 'promoTitle') ? undefined : json['promoTitle'],
        'promoDescription': !(0, runtime_1.exists)(json, 'promoDescription') ? undefined : json['promoDescription'],
        'promoTagline': !(0, runtime_1.exists)(json, 'promoTagline') ? undefined : json['promoTagline'],
        'autoMintContract': !(0, runtime_1.exists)(json, 'autoMintContract') ? undefined : json['autoMintContract'],
        'topHeader': !(0, runtime_1.exists)(json, 'topHeader') ? undefined : json['topHeader'],
        'bottomHeader': !(0, runtime_1.exists)(json, 'bottomHeader') ? undefined : json['bottomHeader'],
        'advertisementBlocks': !(0, runtime_1.exists)(json, 'advertisementBlocks') ? undefined : (json['advertisementBlocks'].map(_1.BrandMapAdvertisementBlockFromJSON)),
        'autoMintToastMessage': !(0, runtime_1.exists)(json, 'autoMintToastMessage') ? undefined : json['autoMintToastMessage'],
        'autoMintToastBackground': !(0, runtime_1.exists)(json, 'autoMintToastBackground') ? undefined : (0, _1.AssetFromJSON)(json['autoMintToastBackground']),
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'autoMintToastTextColor': !(0, runtime_1.exists)(json, 'autoMintToastTextColor') ? undefined : (0, _1.BrandMapColorThemeFromJSON)(json['autoMintToastTextColor']),
        'preAutoMintToastMessage': !(0, runtime_1.exists)(json, 'preAutoMintToastMessage') ? undefined : json['preAutoMintToastMessage'],
        'preAutoMintToastBackground': !(0, runtime_1.exists)(json, 'preAutoMintToastBackground') ? undefined : (0, _1.AssetFromJSON)(json['preAutoMintToastBackground']),
        'loginAccessPoint': !(0, runtime_1.exists)(json, 'loginAccessPoint') ? undefined : (0, _1.LoginAccessPointFromJSON)(json['loginAccessPoint']),
        'allowMarketplaceAccess': !(0, runtime_1.exists)(json, 'allowMarketplaceAccess') ? undefined : json['allowMarketplaceAccess'],
    };
}
exports.BrandMapFromJSONTyped = BrandMapFromJSONTyped;
function BrandMapToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'brand': (0, _1.BrandToJSON)(value.brand),
        'merchant': (0, _1.MerchantToJSON)(value.merchant),
        'slug': value.slug,
        'bannerImage': (0, _1.AssetToJSON)(value.bannerImage),
        'defaultLocation': (0, _1.AddressToJSON)(value.defaultLocation),
        'status': (0, _1.BrandMapStatusToJSON)(value.status),
        'businesses': value.businesses === undefined ? undefined : (value.businesses.map(_1.BusinessToJSON)),
        'mainPromo': (0, _1.OrderDiscountToJSON)(value.mainPromo),
        'promos': value.promos === undefined ? undefined : (value.promos.map(_1.OrderDiscountToJSON)),
        'promoHero': (0, _1.AssetToJSON)(value.promoHero),
        'promoBackground': (0, _1.AssetToJSON)(value.promoBackground),
        'promoCartHero': (0, _1.AssetToJSON)(value.promoCartHero),
        'promoCartBackground': (0, _1.AssetToJSON)(value.promoCartBackground),
        'colorTheme': (0, _1.BrandMapColorThemeToJSON)(value.colorTheme),
        'colorBackground': value.colorBackground,
        'defaultBusiness': value.defaultBusiness,
        'promoTitle': value.promoTitle,
        'promoDescription': value.promoDescription,
        'promoTagline': value.promoTagline,
        'autoMintContract': value.autoMintContract,
        'topHeader': value.topHeader,
        'bottomHeader': value.bottomHeader,
        'advertisementBlocks': value.advertisementBlocks === undefined ? undefined : (value.advertisementBlocks.map(_1.BrandMapAdvertisementBlockToJSON)),
        'autoMintToastMessage': value.autoMintToastMessage,
        'autoMintToastBackground': (0, _1.AssetToJSON)(value.autoMintToastBackground),
        'description': value.description,
        'autoMintToastTextColor': (0, _1.BrandMapColorThemeToJSON)(value.autoMintToastTextColor),
        'preAutoMintToastMessage': value.preAutoMintToastMessage,
        'preAutoMintToastBackground': (0, _1.AssetToJSON)(value.preAutoMintToastBackground),
        'loginAccessPoint': (0, _1.LoginAccessPointToJSON)(value.loginAccessPoint),
        'allowMarketplaceAccess': value.allowMarketplaceAccess,
    };
}
exports.BrandMapToJSON = BrandMapToJSON;
