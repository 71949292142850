"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetReferralRewardThresholdsSortToJSON = exports.GetReferralRewardThresholdsSortFromJSONTyped = exports.GetReferralRewardThresholdsSortFromJSON = exports.GetReferralRewardThresholdsSort = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetReferralRewardThresholdsSort;
(function (GetReferralRewardThresholdsSort) {
    GetReferralRewardThresholdsSort["CreatedAt"] = "createdAt";
    GetReferralRewardThresholdsSort["Expiration"] = "expiration";
    GetReferralRewardThresholdsSort["DpayRewardForReferrer"] = "dpayRewardForReferrer";
    GetReferralRewardThresholdsSort["MinPaymentRequired"] = "minPaymentRequired";
})(GetReferralRewardThresholdsSort = exports.GetReferralRewardThresholdsSort || (exports.GetReferralRewardThresholdsSort = {}));
function GetReferralRewardThresholdsSortFromJSON(json) {
    return GetReferralRewardThresholdsSortFromJSONTyped(json, false);
}
exports.GetReferralRewardThresholdsSortFromJSON = GetReferralRewardThresholdsSortFromJSON;
function GetReferralRewardThresholdsSortFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetReferralRewardThresholdsSortFromJSONTyped = GetReferralRewardThresholdsSortFromJSONTyped;
function GetReferralRewardThresholdsSortToJSON(value) {
    return value;
}
exports.GetReferralRewardThresholdsSortToJSON = GetReferralRewardThresholdsSortToJSON;
