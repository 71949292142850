"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateOrderDiscountBodyToJSON = exports.CreateOrderDiscountBodyFromJSONTyped = exports.CreateOrderDiscountBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function CreateOrderDiscountBodyFromJSON(json) {
    return CreateOrderDiscountBodyFromJSONTyped(json, false);
}
exports.CreateOrderDiscountBodyFromJSON = CreateOrderDiscountBodyFromJSON;
function CreateOrderDiscountBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'label': json['label'],
        'internalName': !(0, runtime_1.exists)(json, 'internalName') ? undefined : json['internalName'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'businesses': !(0, runtime_1.exists)(json, 'businesses') ? undefined : json['businesses'],
        'priority': json['priority'],
        'amount': json['amount'],
        'amountMax': json['amountMax'],
        'subtotalMin': json['subtotalMin'],
        'expiresAt': !(0, runtime_1.exists)(json, 'expiresAt') ? undefined : json['expiresAt'],
        'type': (0, _1.OrderDiscountTypeFromJSON)(json['type']),
        'calculation': (0, _1.OrderDiscountCalculationFromJSON)(json['calculation']),
        'origin': (0, _1.OrderDiscountOriginFromJSON)(json['origin']),
        'isEnabled': json['isEnabled'],
        'nftGroupings': !(0, runtime_1.exists)(json, 'nftGroupings') ? undefined : json['nftGroupings'],
        'codes': !(0, runtime_1.exists)(json, 'codes') ? undefined : json['codes'],
        'isSingleUseUser': json['isSingleUseUser'],
        'isSingleUseSystem': json['isSingleUseSystem'],
        'isStackablePromo': json['isStackablePromo'],
        'isFirstOrder': json['isFirstOrder'],
        'isReferralOnly': !(0, runtime_1.exists)(json, 'isReferralOnly') ? undefined : json['isReferralOnly'],
        'isDisplayedGoFrens': json['isDisplayedGoFrens'],
        'maxRedemptions': !(0, runtime_1.exists)(json, 'maxRedemptions') ? undefined : json['maxRedemptions'],
        'useFrequency': !(0, runtime_1.exists)(json, 'useFrequency') ? undefined : json['useFrequency'],
        'useFrequencyQualifier': !(0, runtime_1.exists)(json, 'useFrequencyQualifier') ? undefined : (0, _1.OrderDiscountFrequencyQualifierFromJSON)(json['useFrequencyQualifier']),
        'menuCategories': !(0, runtime_1.exists)(json, 'menuCategories') ? undefined : json['menuCategories'],
        'menuItems': !(0, runtime_1.exists)(json, 'menuItems') ? undefined : json['menuItems'],
    };
}
exports.CreateOrderDiscountBodyFromJSONTyped = CreateOrderDiscountBodyFromJSONTyped;
function CreateOrderDiscountBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'label': value.label,
        'internalName': value.internalName,
        'description': value.description,
        'businesses': value.businesses,
        'priority': value.priority,
        'amount': value.amount,
        'amountMax': value.amountMax,
        'subtotalMin': value.subtotalMin,
        'expiresAt': value.expiresAt,
        'type': (0, _1.OrderDiscountTypeToJSON)(value.type),
        'calculation': (0, _1.OrderDiscountCalculationToJSON)(value.calculation),
        'origin': (0, _1.OrderDiscountOriginToJSON)(value.origin),
        'isEnabled': value.isEnabled,
        'nftGroupings': value.nftGroupings,
        'codes': value.codes,
        'isSingleUseUser': value.isSingleUseUser,
        'isSingleUseSystem': value.isSingleUseSystem,
        'isStackablePromo': value.isStackablePromo,
        'isFirstOrder': value.isFirstOrder,
        'isReferralOnly': value.isReferralOnly,
        'isDisplayedGoFrens': value.isDisplayedGoFrens,
        'maxRedemptions': value.maxRedemptions,
        'useFrequency': value.useFrequency,
        'useFrequencyQualifier': (0, _1.OrderDiscountFrequencyQualifierToJSON)(value.useFrequencyQualifier),
        'menuCategories': value.menuCategories,
        'menuItems': value.menuItems,
    };
}
exports.CreateOrderDiscountBodyToJSON = CreateOrderDiscountBodyToJSON;
