import React, {ChangeEventHandler, ReactElement} from "react";
import {CreateBrandMapBodyFrontend} from "../../pages/brands/components/BrandMapFormValues";

interface Props {
    topHeader: string;
    bottomHeader: string;
    topHeaderOnChange: (key: keyof CreateBrandMapBodyFrontend) => ChangeEventHandler<HTMLInputElement>;
    bottomHeaderOnChange: (key: keyof CreateBrandMapBodyFrontend) => ChangeEventHandler<HTMLInputElement>;
}

function MerchantBrandHeaders(props: Props): ReactElement {

    return (
        <div className="create-map-page_container create-map-page_main-promo">
            <div className="create-map-page_container_inner create-map-page_main-promo_title">
                <h5>
                    Top Header
                </h5>
                <p>
                    Write a title for the top header. If left empty, this will show "Claim Rewards" by default.
                </p>
                <input
                    placeholder="Eg. Claim Rewards"
                    value={props.topHeader}
                    onChange={props.topHeaderOnChange("topHeader")}
                />
            </div>
            <div className="create-map-page_container_inner create-map-page_main-promo_title">
                <h5>
                    Bottom Header
                </h5>
                <p>
                    Write a title for the bottom header. If left empty, this will show "Other Promotions" by default.
                </p>
                <input
                    placeholder="Eg. Other Promotions"
                    value={props.bottomHeader}
                    onChange={props.bottomHeaderOnChange("bottomHeader")}
                />
            </div>
        </div>
    );
}

export default MerchantBrandHeaders;
