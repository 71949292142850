"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuCategoryToJSON = exports.MenuCategoryFromJSONTyped = exports.MenuCategoryFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function MenuCategoryFromJSON(json) {
    return MenuCategoryFromJSONTyped(json, false);
}
exports.MenuCategoryFromJSON = MenuCategoryFromJSON;
function MenuCategoryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'name': json['name'],
        'business': json['business'],
        'parent': !(0, runtime_1.exists)(json, 'parent') ? undefined : json['parent'],
        'sortOrder': !(0, runtime_1.exists)(json, 'sortOrder') ? undefined : json['sortOrder'],
        'isEnabled': !(0, runtime_1.exists)(json, 'isEnabled') ? undefined : json['isEnabled'],
        'menuSubcategories': !(0, runtime_1.exists)(json, 'menuSubcategories') ? undefined : (json['menuSubcategories'].map(MenuCategoryFromJSON)),
        'menuItems': !(0, runtime_1.exists)(json, 'menuItems') ? undefined : (json['menuItems'].map(_1.MenuItemFromJSON)),
        'serviceAvailabilities': !(0, runtime_1.exists)(json, 'serviceAvailabilities') ? undefined : (json['serviceAvailabilities'].map(_1.BusinessServiceAvailabilityFromJSON)),
        'chowlyId': !(0, runtime_1.exists)(json, 'chowlyId') ? undefined : json['chowlyId'],
        'itsaCheckmateId': !(0, runtime_1.exists)(json, 'itsaCheckmateId') ? undefined : json['itsaCheckmateId'],
    };
}
exports.MenuCategoryFromJSONTyped = MenuCategoryFromJSONTyped;
function MenuCategoryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'name': value.name,
        'business': value.business,
        'parent': value.parent,
        'sortOrder': value.sortOrder,
        'isEnabled': value.isEnabled,
        'menuSubcategories': value.menuSubcategories === undefined ? undefined : (value.menuSubcategories.map(MenuCategoryToJSON)),
        'menuItems': value.menuItems === undefined ? undefined : (value.menuItems.map(_1.MenuItemToJSON)),
        'serviceAvailabilities': value.serviceAvailabilities === undefined ? undefined : (value.serviceAvailabilities.map(_1.BusinessServiceAvailabilityToJSON)),
        'chowlyId': value.chowlyId,
        'itsaCheckmateId': value.itsaCheckmateId,
    };
}
exports.MenuCategoryToJSON = MenuCategoryToJSON;
