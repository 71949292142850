"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateNftGroupingBodyToJSON = exports.UpdateNftGroupingBodyFromJSONTyped = exports.UpdateNftGroupingBodyFromJSON = void 0;
var runtime_1 = require("../runtime");
function UpdateNftGroupingBodyFromJSON(json) {
    return UpdateNftGroupingBodyFromJSONTyped(json, false);
}
exports.UpdateNftGroupingBodyFromJSON = UpdateNftGroupingBodyFromJSON;
function UpdateNftGroupingBodyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'groupingId': json['groupingId'],
        'name': json['name'],
        'sortPriority': !(0, runtime_1.exists)(json, 'sortPriority') ? undefined : json['sortPriority'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'discordUrl': !(0, runtime_1.exists)(json, 'discordUrl') ? undefined : json['discordUrl'],
        'twitterUrl': !(0, runtime_1.exists)(json, 'twitterUrl') ? undefined : json['twitterUrl'],
        'websiteUrl': !(0, runtime_1.exists)(json, 'websiteUrl') ? undefined : json['websiteUrl'],
        'hero': !(0, runtime_1.exists)(json, 'hero') ? undefined : json['hero'],
        'icon': !(0, runtime_1.exists)(json, 'icon') ? undefined : json['icon'],
    };
}
exports.UpdateNftGroupingBodyFromJSONTyped = UpdateNftGroupingBodyFromJSONTyped;
function UpdateNftGroupingBodyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'groupingId': value.groupingId,
        'name': value.name,
        'sortPriority': value.sortPriority,
        'description': value.description,
        'discordUrl': value.discordUrl,
        'twitterUrl': value.twitterUrl,
        'websiteUrl': value.websiteUrl,
        'hero': value.hero,
        'icon': value.icon,
    };
}
exports.UpdateNftGroupingBodyToJSON = UpdateNftGroupingBodyToJSON;
