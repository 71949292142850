"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.NftTrackerToJSON = exports.NftTrackerFromJSONTyped = exports.NftTrackerFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function NftTrackerFromJSON(json) {
    return NftTrackerFromJSONTyped(json, false);
}
exports.NftTrackerFromJSON = NftTrackerFromJSON;
function NftTrackerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'grouping': !(0, runtime_1.exists)(json, 'grouping') ? undefined : (0, _1.NftGroupingFromJSON)(json['grouping']),
        'groupingId': json['groupingId'],
        'collectionId': json['collectionId'],
        'contractAddress': json['contractAddress'],
        'evmChain': (0, _1.EVMChainFromJSON)(json['evmChain']),
        'name': json['name'],
        'openSea': json['openSea'],
        'overrideSupportsInterface': !(0, runtime_1.exists)(json, 'overrideSupportsInterface') ? undefined : json['overrideSupportsInterface'],
        'isIneligibleForLeaderboards': !(0, runtime_1.exists)(json, 'isIneligibleForLeaderboards') ? undefined : json['isIneligibleForLeaderboards'],
    };
}
exports.NftTrackerFromJSONTyped = NftTrackerFromJSONTyped;
function NftTrackerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'grouping': (0, _1.NftGroupingToJSON)(value.grouping),
        'groupingId': value.groupingId,
        'collectionId': value.collectionId,
        'contractAddress': value.contractAddress,
        'evmChain': (0, _1.EVMChainToJSON)(value.evmChain),
        'name': value.name,
        'openSea': value.openSea,
        'overrideSupportsInterface': value.overrideSupportsInterface,
        'isIneligibleForLeaderboards': value.isIneligibleForLeaderboards,
    };
}
exports.NftTrackerToJSON = NftTrackerToJSON;
