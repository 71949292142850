"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuItemSearchEssentialToJSON = exports.MenuItemSearchEssentialFromJSONTyped = exports.MenuItemSearchEssentialFromJSON = void 0;
var runtime_1 = require("../runtime");
function MenuItemSearchEssentialFromJSON(json) {
    return MenuItemSearchEssentialFromJSONTyped(json, false);
}
exports.MenuItemSearchEssentialFromJSON = MenuItemSearchEssentialFromJSON;
function MenuItemSearchEssentialFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': !(0, runtime_1.exists)(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'price': json['price'],
        'taxRate': json['taxRate'],
        'isAlcohol': !(0, runtime_1.exists)(json, 'isAlcohol') ? undefined : json['isAlcohol'],
        'hasCustomizations': !(0, runtime_1.exists)(json, 'hasCustomizations') ? undefined : json['hasCustomizations'],
        'image': !(0, runtime_1.exists)(json, 'image') ? undefined : json['image'],
        'nftGroupings': !(0, runtime_1.exists)(json, 'nftGroupings') ? undefined : json['nftGroupings'],
    };
}
exports.MenuItemSearchEssentialFromJSONTyped = MenuItemSearchEssentialFromJSONTyped;
function MenuItemSearchEssentialToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'price': value.price,
        'taxRate': value.taxRate,
        'isAlcohol': value.isAlcohol,
        'hasCustomizations': value.hasCustomizations,
        'image': value.image,
        'nftGroupings': value.nftGroupings,
    };
}
exports.MenuItemSearchEssentialToJSON = MenuItemSearchEssentialToJSON;
