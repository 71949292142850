"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.MenuItemToJSON = exports.MenuItemFromJSONTyped = exports.MenuItemFromJSON = void 0;
var runtime_1 = require("../runtime");
var _1 = require("./");
function MenuItemFromJSON(json) {
    return MenuItemFromJSONTyped(json, false);
}
exports.MenuItemFromJSON = MenuItemFromJSON;
function MenuItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'createdAt': json['createdAt'],
        'updatedAt': json['updatedAt'],
        'category': json['category'],
        'images': !(0, runtime_1.exists)(json, 'images') ? undefined : (json['images'].map(_1.AssetFromJSON)),
        'name': json['name'],
        'description': !(0, runtime_1.exists)(json, 'description') ? undefined : json['description'],
        'price': json['price'],
        'sortOrder': !(0, runtime_1.exists)(json, 'sortOrder') ? undefined : json['sortOrder'],
        'taxRate': json['taxRate'],
        'isAlcohol': !(0, runtime_1.exists)(json, 'isAlcohol') ? undefined : json['isAlcohol'],
        'isActive': !(0, runtime_1.exists)(json, 'isActive') ? undefined : json['isActive'],
        'isEnabled': !(0, runtime_1.exists)(json, 'isEnabled') ? undefined : json['isEnabled'],
        'customizations': !(0, runtime_1.exists)(json, 'customizations') ? undefined : (json['customizations'].map(_1.MenuCustomizationFromJSON)),
        'nftGroupings': !(0, runtime_1.exists)(json, 'nftGroupings') ? undefined : json['nftGroupings'],
        'chowlyId': !(0, runtime_1.exists)(json, 'chowlyId') ? undefined : json['chowlyId'],
        'chowlyExternalId': !(0, runtime_1.exists)(json, 'chowlyExternalId') ? undefined : json['chowlyExternalId'],
        'itsaCheckmateId': !(0, runtime_1.exists)(json, 'itsaCheckmateId') ? undefined : json['itsaCheckmateId'],
    };
}
exports.MenuItemFromJSONTyped = MenuItemFromJSONTyped;
function MenuItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'createdAt': value.createdAt,
        'updatedAt': value.updatedAt,
        'category': value.category,
        'images': value.images === undefined ? undefined : (value.images.map(_1.AssetToJSON)),
        'name': value.name,
        'description': value.description,
        'price': value.price,
        'sortOrder': value.sortOrder,
        'taxRate': value.taxRate,
        'isAlcohol': value.isAlcohol,
        'isActive': value.isActive,
        'isEnabled': value.isEnabled,
        'customizations': value.customizations === undefined ? undefined : (value.customizations.map(_1.MenuCustomizationToJSON)),
        'nftGroupings': value.nftGroupings,
        'chowlyId': value.chowlyId,
        'chowlyExternalId': value.chowlyExternalId,
        'itsaCheckmateId': value.itsaCheckmateId,
    };
}
exports.MenuItemToJSON = MenuItemToJSON;
