"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderRefundResponsibilityToJSON = exports.OrderRefundResponsibilityFromJSONTyped = exports.OrderRefundResponsibilityFromJSON = exports.OrderRefundResponsibility = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var OrderRefundResponsibility;
(function (OrderRefundResponsibility) {
    OrderRefundResponsibility["DEVOUR"] = "DEVOUR";
    OrderRefundResponsibility["MERCHANT"] = "MERCHANT";
    OrderRefundResponsibility["DELIVERYPROVIDER"] = "DELIVERY_PROVIDER";
    OrderRefundResponsibility["SYSTEMCANCELLED"] = "SYSTEM_CANCELLED";
})(OrderRefundResponsibility = exports.OrderRefundResponsibility || (exports.OrderRefundResponsibility = {}));
function OrderRefundResponsibilityFromJSON(json) {
    return OrderRefundResponsibilityFromJSONTyped(json, false);
}
exports.OrderRefundResponsibilityFromJSON = OrderRefundResponsibilityFromJSON;
function OrderRefundResponsibilityFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.OrderRefundResponsibilityFromJSONTyped = OrderRefundResponsibilityFromJSONTyped;
function OrderRefundResponsibilityToJSON(value) {
    return value;
}
exports.OrderRefundResponsibilityToJSON = OrderRefundResponsibilityToJSON;
