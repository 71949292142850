"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetBusinessOwnOperatingStatusToJSON = exports.GetBusinessOwnOperatingStatusFromJSONTyped = exports.GetBusinessOwnOperatingStatusFromJSON = exports.GetBusinessOwnOperatingStatus = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var GetBusinessOwnOperatingStatus;
(function (GetBusinessOwnOperatingStatus) {
    GetBusinessOwnOperatingStatus["NORMAL"] = "NORMAL";
    GetBusinessOwnOperatingStatus["TEMPORARILYCLOSED"] = "TEMPORARILY_CLOSED";
})(GetBusinessOwnOperatingStatus = exports.GetBusinessOwnOperatingStatus || (exports.GetBusinessOwnOperatingStatus = {}));
function GetBusinessOwnOperatingStatusFromJSON(json) {
    return GetBusinessOwnOperatingStatusFromJSONTyped(json, false);
}
exports.GetBusinessOwnOperatingStatusFromJSON = GetBusinessOwnOperatingStatusFromJSON;
function GetBusinessOwnOperatingStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.GetBusinessOwnOperatingStatusFromJSONTyped = GetBusinessOwnOperatingStatusFromJSONTyped;
function GetBusinessOwnOperatingStatusToJSON(value) {
    return value;
}
exports.GetBusinessOwnOperatingStatusToJSON = GetBusinessOwnOperatingStatusToJSON;
